import React from 'react'
import { Form, Grid, Icon, Pagination } from 'semantic-ui-react'

interface Props {
  filteredDataLength: number
  imagesPerPage: number
  activePage: number
  handlePaginationChange: any
  handleChangeImagesPerPage: any
}

function Controls(props: Props) {
  const { activePage, handlePaginationChange, handleChangeImagesPerPage, imagesPerPage, filteredDataLength } = props

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column floated="left" width={5}>
          <Pagination
            onPageChange={handlePaginationChange}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{
              content: <Icon name="angle left" />,
              icon: true,
            }}
            nextItem={{
              content: <Icon name="angle right" />,
              icon: true,
            }}
            totalPages={Math.ceil(filteredDataLength / imagesPerPage)}
            activePage={activePage}
          />
        </Grid.Column>
        <Grid.Column floated="right" width={3} textAlign="right">
          <Form.Dropdown
            value={imagesPerPage}
            onChange={handleChangeImagesPerPage}
            selection
            options={[
              { key: '20', value: 20, text: '20' },
              { key: '50', value: 50, text: '50' },
              { key: '100', value: 100, text: '100' },
              { key: '200', value: 200, text: '200' },
              { key: '500', value: 500, text: '500' },
              { key: '1000', value: 1000, text: '1000' },
              { key: '5000', value: 5000, text: '5000' },
            ]}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Controls
