import {
  FOLDER_LIST_LOAD_ERRORED,
  FOLDER_LIST_LOAD_FINISHED,
  FOLDER_LIST_LOAD_STARTED,
  FolderListActionTypes,
  FolderListState,
} from './types'

const initialState: FolderListState = {
  folders: [],
  error: false,
  loading: false,
}

export default function folderListReducer(state = initialState, action: FolderListActionTypes): FolderListState {
  switch (action.type) {
    case FOLDER_LIST_LOAD_STARTED:
      return {
        loading: true,
        error: false,
        folders: [],
      }
    case FOLDER_LIST_LOAD_FINISHED:
      return {
        loading: false,
        error: false,
        folders: action.payload.folders,
      }
    case FOLDER_LIST_LOAD_ERRORED:
      return {
        loading: false,
        folders: [],
        error: true,
      }
    default:
      return state
  }
}
