/* eslint-disable no-underscore-dangle */
/* eslint-disable no-eval */
import React, { useEffect, useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Icon,
  Image,
  Message,
  Popup,
  TextArea,
} from 'semantic-ui-react'
import arrayBooleanReducer, { ActionType } from 'helpers/arrayBooleanReducer'
import { useSelector, useDispatch } from 'react-redux'
import Controls from 'components/Folder/Controls'
import defaultFilters from 'helpers/defaultFilters'
import { history } from 'helpers/history'
import { GlobalState } from 'store/configureStore'
import { Filter, Image as ImageType } from 'types'
import {
  appendToLocalStorage,
  getFromLocalStorage,
  removeFromArrayByIndexInLocalStorage,
  getFromArrayByIndexInLocalStorage,
} from 'helpers/localStorageManager'
import { cleanError, loadImageList } from 'store/folder/actions'
import { CONFIG_LOCAL_STORAGE_KEY, FILTER_LOCAL_STORAGE_KEY } from 'helpers/constants'
import styles from './Folder.module.css'

interface Props {
  match: {
    params: {
      folder: string
      api: string
    }
  }
}

function Folder(props: Props) {
  const { match } = props

  const imageList = useSelector((state: GlobalState) => state.folder.images)
  const name = useSelector((state: GlobalState) => state.folder.name)
  const loading = useSelector((state: GlobalState) => state.folderList.loading || state.folder.loading)
  const errored = useSelector((state: GlobalState) => state.folderList.error || state.folder.error)

  const dispatch = useDispatch()

  const folderName = match.params.folder
  const apiAddress = getFromArrayByIndexInLocalStorage(CONFIG_LOCAL_STORAGE_KEY, parseInt(match.params.api, 10))

  const [filters, setFilters] = useState(JSON.parse(getFromLocalStorage(FILTER_LOCAL_STORAGE_KEY, defaultFilters)))

  const [usedFilters, dispatchFilterAction] = useReducer(arrayBooleanReducer, Array(filters.length).fill(false))

  const [filteredData, setFilteredData] = useState(imageList)
  const [activePage, setActivePage] = useState(1)

  const [customFilterText, setCustomFilterText] = useState('')
  const [labelText, setLabelText] = useState('')

  const [isError, setErrorState] = useState(false)

  const [imagesPerPage, setImagesPerPage] = useState(20)

  const [coordinates, setCoordinates] = useState({
    coordinates: { x: 0, y: 0 },
    mouseCoordinates: { x: 0, y: 0 },
    show: false,
  })

  const handlePaginationChange = (_event: any, pagination: any) => {
    setActivePage(pagination.activePage)
  }

  const handleChangeImagesPerPage = (_event: any, im: any) => {
    setImagesPerPage(im.value)
  }

  useEffect(() => {
    if (folderName !== name && !loading) {
      if (errored && !apiAddress) {
        dispatch(cleanError())
        history.replace('/')
      } else {
        dispatch(loadImageList(apiAddress, folderName))
      }
    } else {
      document.title = folderName
    }
  }, [apiAddress, folderName, name, errored, loading, dispatch])

  useEffect(() => {
    const filterdImages = imageList.filter((element, index, array) =>
      filters
        .filter((_f: Filter, i: number) => usedFilters[i])
        .every((f: Filter) => {
          try {
            const fn = eval(f.fn)
            return fn(element, index, array)
          } catch (e) {
            return false
          }
        }),
    )

    setFilteredData(filterdImages)
    if ((activePage - 1) * imagesPerPage > filterdImages.length) {
      setActivePage(1)
    }

    setErrorState(false)
  }, [usedFilters, imageList, filters, activePage, imagesPerPage])

  return (
    <>
      <div className={styles.grid}>
        <aside className={styles.folderSidebar}>
          <div className={styles.title}>
            <Breadcrumb>
              <Breadcrumb.Section>
                <Link to={`/${match.params.api}`}>Folder list</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>{match.params.folder}</Breadcrumb.Section>
            </Breadcrumb>
          </div>
          <div className={styles.filters}>
            <Form>
              {filters.map(
                // @ts-ignore
                (filter, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Form.Field key={i}>
                    <Popup
                      content={filter.fn}
                      position="top left"
                      trigger={
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Checkbox
                                toggle
                                label={filter.label}
                                name="selectFilter"
                                checked={usedFilters[i]}
                                onContextMenu={(e: any) => {
                                  e.preventDefault()
                                  setCustomFilterText(filter.fn)
                                }}
                                onChange={() =>
                                  dispatchFilterAction({
                                    type: ActionType.ToggleItem,
                                    index: i,
                                  })
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={2}>
                              {!usedFilters[i] && (
                                <Icon
                                  className={styles.deleteFilter}
                                  onClick={() => {
                                    dispatchFilterAction({
                                      type: ActionType.RemoveItem,
                                      index: i,
                                    })
                                    setFilters(removeFromArrayByIndexInLocalStorage(FILTER_LOCAL_STORAGE_KEY, i))
                                  }}
                                  name="close"
                                />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      }
                    />
                  </Form.Field>
                ),
              )}
            </Form>
          </div>
          <div className={styles.add}>
            <Form error={isError}>
              <Form.Input
                type="text"
                onChange={(_event: any, field: any) => setLabelText(field.value)}
                placeholder="Label"
              />
              <Form.Field error={isError}>
                <TextArea
                  onChange={(_event: any, field: any) => setCustomFilterText(field.value)}
                  placeholder="Custom filter"
                  value={customFilterText}
                  className={styles.filterText}
                />
              </Form.Field>
              <Message
                error
                header="That is not a function!"
                content={
                  <span>
                    Try to look at already used filters. For more information, please look at a{' '}
                    <a href="https://dopice.sk/naS">JavaScript filter documentation</a> or at a{' '}
                    <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference">JavaScript reference</a>
                    .
                  </span>
                }
              />
              <br />
              <Button
                primary
                type="button"
                onClick={() => {
                  try {
                    if (customFilterText === '') {
                      throw new Error('Wrong rule')
                    }
                    eval(customFilterText)
                    setFilters(
                      appendToLocalStorage(FILTER_LOCAL_STORAGE_KEY, {
                        fn: customFilterText,
                        label: labelText !== '' ? labelText : customFilterText,
                      }),
                    )
                    dispatchFilterAction({ type: ActionType.AddItem })
                    setErrorState(false)
                  } catch (e) {
                    setErrorState(true)
                  }
                }}
              >
                Add custom filter
              </Button>
            </Form>
          </div>
        </aside>
        <main className={styles.folderContent}>
          {filteredData && filteredData.length > 0 && folderName === name ? (
            <>
              <Controls
                activePage={activePage}
                handlePaginationChange={handlePaginationChange}
                handleChangeImagesPerPage={handleChangeImagesPerPage}
                imagesPerPage={imagesPerPage}
                filteredDataLength={filteredData.length}
              />
              <Grid>
                {filteredData
                  .slice((activePage - 1) * imagesPerPage, (activePage - 1) * imagesPerPage + imagesPerPage)
                  .map((o: ImageType) => {
                    return (
                      <Grid.Row key={o.path} className={styles.imageContainer}>
                        <Grid.Column width={14}>
                          <Image
                            src={`${apiAddress}/api/images/${o.path}`}
                            onMouseUp={() =>
                              setCoordinates({
                                coordinates: { x: 0, y: 0 },
                                mouseCoordinates: { x: 0, y: 0 },
                                show: false,
                              })
                            }
                            onMouseDown={(event: React.MouseEvent<HTMLImageElement>) => {
                              setCoordinates({
                                coordinates: {
                                  x:
                                    (event.clientX - event.currentTarget.getBoundingClientRect().left) /
                                    event.currentTarget.clientWidth,
                                  y:
                                    (event.clientY - event.currentTarget.getBoundingClientRect().top) /
                                    event.currentTarget.clientHeight,
                                },
                                mouseCoordinates: {
                                  x: event.clientX,
                                  y: event.clientY,
                                },
                                show: true,
                              })
                            }}
                          />
                          <Popup
                            content={
                              <Container text>
                                {Object.entries(o).map((a: any[], i) => {
                                  return (
                                    a[1] !== null && (
                                      <span className={styles.readedLine} key={a[0]}>
                                        {a[0]}: {a[1].toString()}
                                      </span>
                                    )
                                  )
                                })}
                              </Container>
                            }
                            pinned
                            on="click"
                            position="right center"
                            trigger={
                              <div className={styles.readedValue}>
                                {o.field && (
                                  <span>
                                    {o.field}: {o.index}
                                  </span>
                                )}
                                <br />
                                {o._type && (
                                  <>
                                    <span>
                                      Folder: <b>{o.folder}</b>
                                    </span>
                                    <br />
                                  </>
                                )}
                                <span>
                                  Read value: <b>{o.prediction_final}</b>
                                </span>
                              </div>
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <Button
                            className={styles.topActionButton}
                            secondary
                            type="button"
                            onClick={() => {
                              setFilters(
                                appendToLocalStorage(FILTER_LOCAL_STORAGE_KEY, {
                                  fn: `(o) => o.folder === '${o.folder}'`,
                                  label: o.folder,
                                }),
                              )
                              dispatchFilterAction({ type: ActionType.AddItem })
                            }}
                          >
                            Filter folder
                          </Button>
                          <br />
                          <Button
                            secondary
                            type="button"
                            onClick={() => {
                              setFilters(
                                o.field !== null
                                  ? appendToLocalStorage(FILTER_LOCAL_STORAGE_KEY, {
                                      fn: `(o) => o.field === '${o.field}'`,
                                      label: o.field,
                                    })
                                  : appendToLocalStorage(FILTER_LOCAL_STORAGE_KEY, {
                                      fn: `(o) => o._type === '${o._type}'`,
                                      label: o._type,
                                    }),
                              )
                              dispatchFilterAction({ type: ActionType.AddItem })
                            }}
                          >
                            Filter type
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )
                  })}
              </Grid>
              <Controls
                activePage={activePage}
                handlePaginationChange={handlePaginationChange}
                handleChangeImagesPerPage={handleChangeImagesPerPage}
                imagesPerPage={imagesPerPage}
                filteredDataLength={filteredData.length}
              />
            </>
          ) : (
            <Container className={styles.big}>
              <Message warning header="No image matches filters" list={['Try to change filters']} />
            </Container>
          )}
        </main>
      </div>
      {coordinates.show && (
        <div
          className={styles.coordinates}
          style={{
            top: `${coordinates.mouseCoordinates.y + 10}px`,
            left: `${coordinates.mouseCoordinates.x + 10}px`,
          }}
        >{`[${coordinates.coordinates.x.toFixed(3)}, ${coordinates.coordinates.y.toFixed(3)}]`}</div>
      )}
    </>
  )
}

export default Folder
