import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Icon,
  Button,
  Container,
  Form,
  Grid,
  Header,
  List,
  Loader,
  Placeholder,
  Segment,
  Radio,
} from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import loadFolderList from 'store/folderList/actions'
import { GlobalState } from 'store/configureStore'

import {
  appendToLocalStorage,
  getFromLocalStorage,
  getFromArrayByIndexInLocalStorage,
  removeFromArrayByIndexInLocalStorage,
} from 'helpers/localStorageManager'
import { CONFIG_LOCAL_STORAGE_KEY } from 'helpers/constants'

import defaultAddresses from 'helpers/defaultAddresses'
import { history } from 'helpers/history'
import useDebounce from 'helpers/useDebounce'

import styles from './FolderList.module.css'

interface Props {
  match?: {
    params: {
      api: string
    }
  }
}

function FolderList(props: Props) {
  const { match } = props

  const folderList = useSelector((state: GlobalState) => state.folderList.folders)
  const folderListLoading = useSelector((state: GlobalState) => state.folderList.loading)
  const folderListError = useSelector((state: GlobalState) => state.folderList.error)
  const dispatch = useDispatch()

  const [addresses, setAddresses] = useState(
    JSON.parse(getFromLocalStorage(CONFIG_LOCAL_STORAGE_KEY, defaultAddresses)),
  )

  const [addressText, changeAddressText] = useState('')

  const [filter, setFilter] = useState('')

  const debouncedFilter = useDebounce(filter, 500)

  const [filteredFolderList, setFilteredFolderList] = useState(folderList)

  useEffect(() => {
    if (debouncedFilter && debouncedFilter !== '') {
      setFilteredFolderList(
        folderList.filter(folder => folder.link.toLowerCase().includes(debouncedFilter.toLowerCase())),
      )
    } else {
      setFilteredFolderList(folderList)
    }
  }, [debouncedFilter, folderList])

  let initialSelectedValue = 0
  if (match) {
    const parsedApi = parseInt(match.params.api, 10)
    if (parsedApi < addresses.length) {
      initialSelectedValue = parsedApi
    }
  }

  const [selectedAddress, selectAddress] = useState(initialSelectedValue)

  const changeAddress = (index: number) => {
    selectAddress(index)
    history.replace(`/${index}`)
  }

  const removeApi = (index: number) => {
    if (index === selectedAddress) {
      if (index > 0) {
        selectAddress(selectedAddress - 1)
      } else if (addresses.length > 1) {
        selectAddress(addresses.length - 2)
      }
    } else if (index < selectedAddress) {
      selectAddress(selectedAddress - 1)
    }
    setAddresses(removeFromArrayByIndexInLocalStorage(CONFIG_LOCAL_STORAGE_KEY, index))
  }

  useEffect(() => {
    document.title = 'Out Server'
    dispatch(loadFolderList(getFromArrayByIndexInLocalStorage(CONFIG_LOCAL_STORAGE_KEY, selectedAddress)))
  }, [selectedAddress, addresses, dispatch])

  return (
    <Grid className={styles.folderListContainer}>
      <Grid.Column width={3} className={styles.addressBar}>
        <Container>
          {addresses.length > 0 && (
            <Segment
              color="yellow"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Form>
                {addresses.map((address: string, i: number) => (
                  <Grid key={address}>
                    <Grid.Row>
                      <Grid.Column width={14}>
                        <Form.Field>
                          <Radio
                            toggle
                            label={address}
                            name="addresses"
                            value={i}
                            checked={selectedAddress === i}
                            onChange={(event: any, field: any) => changeAddress(field.value)}
                            onContextMenu={(e: any) => {
                              e.preventDefault()
                              changeAddressText(address)
                            }}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Icon className={styles.deleteFilter} onClick={() => removeApi(i)} name="close" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))}
              </Form>
            </Segment>
          )}
          <Segment color="blue">
            <Form size="small">
              <Form.Input
                label="Add backend address"
                type="url"
                value={addressText}
                onChange={(_event: any, field: any) => changeAddressText(field.value)}
              />
              <Button
                primary
                type="button"
                onClick={() => {
                  setAddresses(appendToLocalStorage(CONFIG_LOCAL_STORAGE_KEY, addressText))
                }}
              >
                Add
              </Button>
            </Form>
          </Segment>
          <Segment color="green">
            <Form size="small">
              <Form.Input
                label="Filter"
                type="text"
                value={filter}
                onChange={(_event: any, field: any) => setFilter(field.value)}
              />
              <Button
                primary
                type="button"
                onClick={() => {
                  setFilter('')
                }}
              >
                Clear
              </Button>
            </Form>
          </Segment>
        </Container>
      </Grid.Column>
      <Grid.Column width={12}>
        {addresses.length > 0 && (
          <Container>
            {selectedAddress && folderListError ? (
              <Segment color="red">
                <p>Unable to load the folder list from the API. Check if you are connected to a valid API endpoint.</p>
              </Segment>
            ) : (
              <Segment color="blue">
                <Header as="h1" textAlign="center">
                  Folders list
                </Header>
                {folderListLoading ? (
                  <>
                    <Loader active>Loading</Loader>
                    <Placeholder>
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Line />
                    </Placeholder>
                  </>
                ) : (
                  <List>
                    {filteredFolderList.map(folder => {
                      return (
                        <List.Item key={folder.link}>
                          <List.Icon name="folder" />
                          <List.Content>
                            <List.Header>
                              <Link to={`${selectedAddress}/${folder.link}`}>{folder.link}</Link>
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      )
                    })}
                  </List>
                )}
              </Segment>
            )}
          </Container>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default FolderList
