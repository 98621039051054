import {
  FOLDER_LOAD_ERRORED,
  FOLDER_LOAD_FINISHED,
  FOLDER_LOAD_STARTED,
  FOLDER_CLEAN_ERROR,
  FolderActionTypes,
  FolderState,
} from './types'

const initialState: FolderState = {
  images: [],
  error: false,
  loading: false,
  name: '',
}

export default function folderListReducer(state = initialState, action: FolderActionTypes): FolderState {
  switch (action.type) {
    case FOLDER_LOAD_STARTED:
      return {
        images: [],
        error: false,
        loading: true,
        name: '',
      }
    case FOLDER_LOAD_FINISHED:
      return {
        loading: false,
        error: false,
        images: action.payload.images,
        name: action.payload.folderName,
      }
    case FOLDER_LOAD_ERRORED:
      return {
        loading: false,
        error: true,
        images: [],
        name: '',
      }
    case FOLDER_CLEAN_ERROR:
      return {
        ...state,
        error: false,
      }
    default:
      return state
  }
}
