import produce from 'immer'

interface AddItemAction {
  type: ActionType.AddItem
}

interface ToggleAction {
  type: ActionType.ToggleItem
  index: number
}

interface RemoveItemAction {
  type: ActionType.RemoveItem
  index: number
}

export enum ActionType {
  AddItem,
  ToggleItem,
  RemoveItem,
}

type Action = AddItemAction | ToggleAction | RemoveItemAction

const arrayBooleanReducer = (state: boolean[], action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case ActionType.AddItem:
        draft.push(true)
        break
      case ActionType.ToggleItem:
        // eslint-disable-next-line no-param-reassign
        draft[action.index] = !draft[action.index]
        break
      case ActionType.RemoveItem:
        draft.splice(action.index, 1)
        break
      default:
        throw new Error()
    }
  })

export default arrayBooleanReducer
