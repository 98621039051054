export const saveToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const getFromLocalStorage = (key: string, defaultValue: any) => {
  const item = localStorage.getItem(key)
  if (!item) saveToLocalStorage(key, defaultValue)
  return item || defaultValue
}

export const appendToLocalStorage = (key: string, newData: any) => {
  const data = localStorage.getItem(key)
  if (!data) {
    saveToLocalStorage(key, JSON.stringify(newData))
    return newData
  }
  const parsedData = JSON.parse(data)
  parsedData.push(newData)

  saveToLocalStorage(key, JSON.stringify(parsedData))
  return parsedData
}

export const removeFromArrayByIndexInLocalStorage = (key: string, index: number) => {
  const data = localStorage.getItem(key)
  if (!data) {
    return []
  }
  const parsedData = JSON.parse(data)
  if (parsedData.length < index) {
    return parsedData
  }
  parsedData.splice(index, 1)
  saveToLocalStorage(key, JSON.stringify(parsedData))

  return parsedData
}

export const getFromArrayByIndexInLocalStorage = (key: string, index: number) => {
  const data = localStorage.getItem(key)
  if (!data) {
    return []
  }
  const parsedData = JSON.parse(data)
  if (parsedData.length < index) {
    return parsedData[0]
  }
  return parsedData[index]
}
