import { Folder } from 'types'

export interface FolderListState {
  folders: Folder[]
  error: boolean
  loading: boolean
}

export const FOLDER_LIST_LOAD_STARTED = 'FOLDER_LIST_LOAD_STARTED'
export const FOLDER_LIST_LOAD_FINISHED = 'FOLDER_LIST_LOAD_FINISHED'
export const FOLDER_LIST_LOAD_ERRORED = 'FOLDER_LIST_LOAD_ERRORED'

interface FolderListLoadStarted {
  type: typeof FOLDER_LIST_LOAD_STARTED
}

interface FolderListLoadFinished {
  type: typeof FOLDER_LIST_LOAD_FINISHED
  payload: {
    folders: Folder[]
  }
}

interface FolderListLoadErrored {
  type: typeof FOLDER_LIST_LOAD_ERRORED
}

export type FolderListActionTypes = FolderListLoadStarted | FolderListLoadFinished | FolderListLoadErrored
