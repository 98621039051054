import { ThunkDispatch } from 'redux-thunk'
import Axios from 'axios'
import {
  FOLDER_LIST_LOAD_ERRORED,
  FOLDER_LIST_LOAD_FINISHED,
  FOLDER_LIST_LOAD_STARTED,
  FolderListActionTypes,
  FolderListState,
} from './types'

export default function loadFolderList(apiAddress: string) {
  return async (dispatch: ThunkDispatch<FolderListState, null, FolderListActionTypes>) => {
    dispatch({
      type: FOLDER_LIST_LOAD_STARTED,
    })
    try {
      if (apiAddress.length > 0) {
        const folderListRequest = await Axios(`${apiAddress}/api/folders/`, {
          timeout: 5000,
        })
        const folders = folderListRequest.data
        dispatch({
          type: FOLDER_LIST_LOAD_FINISHED,
          payload: {
            folders,
          },
        })
      } else {
        dispatch({
          type: FOLDER_LIST_LOAD_ERRORED,
        })
      }
    } catch {
      dispatch({
        type: FOLDER_LIST_LOAD_ERRORED,
      })
    }
  }
}
