import Axios from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import {
  FOLDER_LOAD_ERRORED,
  FOLDER_LOAD_FINISHED,
  FOLDER_LOAD_STARTED,
  FOLDER_CLEAN_ERROR,
  FolderActionTypes,
  FolderState,
} from './types'

export function cleanError() {
  return {
    type: FOLDER_CLEAN_ERROR,
  }
}

export function loadImageList(apiAddress: string, folderName: string) {
  return async (dispatch: ThunkDispatch<FolderState, null, FolderActionTypes>) => {
    dispatch({
      type: FOLDER_LOAD_STARTED,
    })
    try {
      if (apiAddress.length > 0) {
        const folderRequest = await Axios(`${apiAddress}/api/folders/${folderName}/`, {
          timeout: 5000,
        })
        const images = folderRequest.data
        dispatch({
          type: FOLDER_LOAD_FINISHED,
          payload: {
            images,
            folderName,
          },
        })
      } else {
        dispatch({
          type: FOLDER_LOAD_ERRORED,
        })
      }
    } catch {
      dispatch({
        type: FOLDER_LOAD_ERRORED,
      })
    }
  }
}
