import React from 'react'
import { Route, Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { history } from 'helpers/history'
import { configureStore } from 'store/configureStore'
import Folder from 'page/Folder/Folder'
import FolderList from 'page/FolderList/FolderList'

function App() {
  const store = configureStore()

  return (
    <Provider store={store}>
      <Router history={history}>
        <Route path="/:api?" exact component={FolderList} />
        <Route path="/:api/:folder" exact component={Folder} />
      </Router>
    </Provider>
  )
}

export default App
