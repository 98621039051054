import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import reduxThunk from 'redux-thunk'
import folderListReducer from 'store/folderList/reducer'
import { FolderListState } from 'store/folderList/types'
import folderReducer from 'store/folder/reducer'
import { FolderState } from 'store/folder/types'

export function configureStore() {
  const rootReducer = combineReducers({
    folder: folderReducer,
    folderList: folderListReducer,
  })
  return createStore(
    rootReducer,
    {},
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(reduxThunk),
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }),
        )
      : applyMiddleware(reduxThunk),
  )
}

export interface GlobalState {
  folder: FolderState
  folderList: FolderListState
}
