import { Image } from 'types'

export interface FolderState {
  images: Image[]
  loading: boolean
  error: boolean
  name: string
}

export const FOLDER_LOAD_STARTED = 'FOLDER_LOAD_STARTED'
export const FOLDER_LOAD_FINISHED = 'FOLDER_LOAD_FINISHED'
export const FOLDER_LOAD_ERRORED = 'FOLDER_LOAD_ERRORED'
export const FOLDER_CLEAN_ERROR = 'FOLDER_CLEAN_ERROR'

interface FolderLoadStarted {
  type: typeof FOLDER_LOAD_STARTED
}

interface FolderLoadFinished {
  type: typeof FOLDER_LOAD_FINISHED
  payload: {
    images: Image[]
    folderName: string
  }
}

interface FolderLoadErrored {
  type: typeof FOLDER_LOAD_ERRORED
}

interface FolderCleanError {
  type: typeof FOLDER_CLEAN_ERROR
}

export type FolderActionTypes = FolderLoadStarted | FolderLoadFinished | FolderLoadErrored | FolderCleanError
